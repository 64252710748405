import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { ENABLE_MEMBERSHIP } from 'gatsby-env-variables';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import BackgroundImage from 'ui-kit/background-image/background-image';
import PageSection from 'ui-kit/page-section/page-section';

import BirdiModal from 'components/birdi-modal/birdi-modal';
import CookieBanner from 'components/cookie-banner/cookie-banner.component';
import Footer from 'components/footer/footer.component';
import { PageLayoutProps } from 'components/layouts/page/page.props';
import { leftNavigationPaths } from 'components/navigation/navigation';
import Navigation from 'components/navigation/navigation.component';
import { NavPath } from 'components/navigation/navigation.props';
import SEO from 'components/seo/seo.component';

import { accountIsMembershipSelector } from 'state/account/account.selectors';

import { InitOptimize } from 'util/google_optimize/optimize_helper';
import { filterNavigationPaths } from 'util/navigation';

import useUmichSite from 'hooks/useUmichSite';

import withBackgroundImage from 'hoc/withBackgroundImage';
import withBasicAuth from 'hoc/withBasicAuth';
import withSecurity from 'hoc/withSecurity';
import withSessionExpiration from 'hoc/withSessionExpiration';

import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

import './page.layout.scss';

const MarketingPageLayout = ({
    children,
    headerImage,
    headerImageStyle,
    headerImageClassName,
    isSecurePage,
    isLoggedIn,
    showMainMenu = false,
    pageContentClassName,
    metaData,
    suppressApplicationPage,
    mailingAddress,
    physicalAddress,
    specialtyPhysicalAddress,
    specialtyMailingAddress
}: PageLayoutProps) => {
    const data = MarketingMenusQuery();
    const replacePath = (path: string) => (path === `/` ? path : path.replace(/\/$/, ``));
    const pageLocation = replacePath(useLocation().pathname);
    const rightNavPaths = data.allMenuLinkContentMain.nodes;
    const isAccountMembership = useSelector(accountIsMembershipSelector);
    const isMembership: boolean | undefined = ENABLE_MEMBERSHIP && isAccountMembership;
    const isUmichSite = useUmichSite();

    const filterPaths = useCallback(
        (navItem: NavPath) => filterNavigationPaths(navItem, isSecurePage, isLoggedIn, !!isMembership, isUmichSite),
        [isMembership, isLoggedIn, isSecurePage, isUmichSite]
    );

    const rightNavFilteredPaths = useMemo(
        () => rightNavPaths.filter((navItem: NavPath) => filterPaths(navItem)),
        [rightNavPaths, filterPaths]
    );

    const leftNavFilteredPaths = useMemo(() => {
        return leftNavigationPaths.filter((navItem: NavPath) => filterPaths(navItem));
    }, [filterPaths]);

    const pageClasses = classNames('page');
    const pageContentClasses = classNames('page-content', pageContentClassName);

    // See if there is any Drupal Application Page content that matches this page URL
    const applicationPageParagraphs = data.allNodeApplicationPage.nodes.filter(
        (node: any) => pageLocation === node.path?.alias?.toString()
    );

    useEffect(() => {
        InitOptimize();
    }, []);

    return (
        <div className={pageClasses}>
            <SEO
                nodeTitle={metaData?.nodeTitle}
                title={metaData?.title}
                abstract={metaData?.abstract}
                description={metaData?.description}
                keywords={metaData?.keywords}
                applicationPageMetaData={applicationPageParagraphs[0]?.field_meta_tags}
                pageType={'marketing'}
            >
                {children}
            </SEO>
            <Navigation
                rightMenuPaths={showMainMenu ? rightNavFilteredPaths : []}
                isSecurePage={isSecurePage}
                isLoggedIn={isLoggedIn}
                leftMenuPaths={leftNavFilteredPaths}
            />
            {headerImage && (
                <BackgroundImage image={headerImage} style={headerImageStyle} className={headerImageClassName} />
            )}
            <div className={pageContentClasses}>
                {children}
                {!suppressApplicationPage &&
                    applicationPageParagraphs[0]?.relationships?.field_application_page_content.map(
                        (paragraph: any, index: number) => (
                            <PageSection key={`application_page_section_${index}`}>
                                {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                            </PageSection>
                        )
                    )}
            </div>
            <BirdiModal />
            <Footer
                isMarketingLandingPage
                copyrightYear={new Date().getFullYear().toString()}
                legalPaths={data.allMenuLinkContentFooterLegal.nodes}
                physicalAddress={
                    physicalAddress ? physicalAddress : data.allBlockContentAddresses.nodes[0]?.field_physical_address
                }
                mailingAddress={
                    mailingAddress ? mailingAddress : data.allBlockContentAddresses.nodes[0]?.field_mailing_address
                }
                specialtyPhysicalAddress={
                    specialtyPhysicalAddress
                        ? specialtyPhysicalAddress
                        : data.allBlockContentAddresses.nodes[0]?.field_specialty_physical_address
                }
                specialtyMailingAddress={
                    specialtyMailingAddress
                        ? specialtyMailingAddress
                        : data.allBlockContentAddresses.nodes[0]?.field_specialty_mailing_address
                }
            />
            <CookieBanner />
        </div>
    );
};

export default withBasicAuth(withSessionExpiration(withSecurity(withBackgroundImage(MarketingPageLayout, ''))));

export const MarketingMenusQuery = () => {
    const MenusAndApplicationPage = useStaticQuery<any>(graphql`
        {
            allMenuLinkContentMain(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    isButton: field_menu_cta
                    link {
                        url
                    }
                    label: title
                    requiresAccountAuth: field_requires_account_auth
                    requiresMembership: field_requires_membership
                    isMobile: field_menu_only_mobile
                    buttonVariant: field_menu_button_variant
                    langcode
                }
            }
            allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
                nodes {
                    link {
                        url
                    }
                    label: title
                    langcode
                }
            }
            allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
                nodes {
                    field_physical_address
                    field_mailing_address
                    field_specialty_mailing_address
                    field_specialty_physical_address
                }
            }
            allNodeApplicationPage {
                nodes {
                    title
                    id
                    drupal_internal__nid
                    relationships {
                        field_application_page_content {
                            ...supportedParagraphs
                        }
                    }
                    path {
                        langcode
                        alias
                    }
                    field_meta_tags {
                        title
                        description
                        abstract
                        keywords
                    }
                }
            }
        }
    `);
    return MenusAndApplicationPage;
};
